export const sansFamList = [
  "freight-sans-pro",
  "Calibre",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  "sans-serif"
];

export const serifFamList = ["freight-text-pro", "Georgia", "serif"];

export const sansFam = sansFamList.join(",");
export const serifFam = serifFamList.join(",");

export default {
  sansFamList,
  serifFamList,
  sansFam,
  serifFam
};
