export const colors = {
  jade: `#00ab7c`,
  shamrock: `#32bb96`,
  downy: `#66ccb0`,
  mountainMeadow: `#1CA086`,
  pomegranate: `#f4501e`,
  apricot: `#f2957a`,
  codGray: `#111111`,
  shark: `#29292c`,
  baliHai: `#8898aa`,
  grayNickel: `#b5b5c0`,
  white: `#fff`,
  black: `#000`,
};

export const colorPointers = {
  brandMain: colors.jade,
  brandLight: colors.shamrock,
  brandLighter: colors.downy,
};

export default {
  ...colors,
  ...colorPointers,
};
