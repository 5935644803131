import Typography from "typography";
import verticalRhythm from "compass-vertical-rhythm";

import colors from "./colors";
import fonts from "./fonts";

const typography = new Typography({
  baseFontSize: "20px",
  baseLineHeight: 1.5,
  headerFontFamily: fonts.sansFamList,
  bodyFontFamily: fonts.sansFamList,
  headerColor: `${colors.pomegranate}`,
  bodyColor: "#0a0a0a",
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
    const vr = verticalRhythm({
      baseFontSize: "17px",
      baseLineHeight: "24.65px",
    });
    return {
      form: {
        marginBottom: 0,
      },
      a: {
        color: `#222`,
        fontFamily: fonts.sansFam,
        fontWeight: `bold`,
        textDecoration: "none",
        transition: `all 100ms cubic-bezier(0.4, 0, 0.2, 1)`,
        borderBottom: `3px solid var(--highlightColor)`,
      },
      "a:hover, a:active": {
        color: `white`,
        background: `var(--highlightColor)`,
      },
      // Headers in components that are not content should rely on
      // spacers for vertical margin layout.
      "h1, h2, h3, h4, h5, h6": {
        marginTop: 0,
        marginBottom: 0,
      },
      // Blockquote styles.
      blockquote: {
        ...scale(1 / 5),
        borderLeft: `${rhythm(6 / 16)} solid var(--highlightColor)`,
        paddingLeft: rhythm(10 / 16),
        fontStyle: "italic",
        marginLeft: 0,
        marginRight: 0,
      },
      "blockquote > :last-child": {
        marginBottom: 0,
      },
      "blockquote cite": {
        ...adjustFontSizeTo(options.baseFontSize),
        color: options.bodyColor,
        fontStyle: "normal",
        fontWeight: options.bodyWeight,
      },
      "blockquote cite:before": {
        content: '"— "',
      },
      code: {
        ...scale(-0.3),
      },
      // see presets.Phablet
      "@media (max-width: 550px)": {
        html: {
          ...vr.establishBaseline(),
        },
        a: {
          borderBottom: `2px solid var(--highlightColor)`,
        },
        blockquote: {
          borderLeft: `${rhythm(3 / 16)} solid var(--highlightColor)`,
          paddingLeft: rhythm(9 / 16),
          fontStyle: "italic",
          marginLeft: rhythm(-3 / 4),
          marginRight: 0,
        },
      },
    };
  },
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
  typography.injectStyles();
}

const { rhythm, scale } = typography;
export { rhythm, scale, typography as default };
