import React from "react";

import BlockLink from "../block-link";
import { rhythm, scale } from "../../utils/typography";
import { sansFam } from "../../utils/fonts";
import logo from "../hexfox-logo.svg";

const HeaderLogo = ({ textColor }) => {
  return (
    <BlockLink
      to="/"
      css={{
        color: `inherit`,
        display: `inline-block`,
        textDecoration: `none`,
        "&:hover": {
          background: `none`,
          borderBottom: `none`,
          boxShadow: `none`,
        },
      }}
    >
      <img
        src={logo}
        alt="Hexfox Logo"
        css={{
          display: `inline-block`,
          height: rhythm(1.2),
          width: rhythm(1.2),
          margin: 0,
          marginTop: `4px`,
          marginRight: rhythm(0.5),
          verticalAlign: `middle`,
        }}
      />
      <h5
        css={{
          ...scale(2 / 5),
          color: textColor,
          fontFamily: sansFam,
          fontWeight: 700,
          lineHeight: 1,
          display: `inline-block`,
          margin: 0,
          verticalAlign: `middle`,
          borderBottom: `3px solid transparent`,
          paddingBottom: `2px`,
          transition: `color .3s`,
          "& .spin": {
            display: `inline-block`,
            position: `relative`,
            transform: `perspective(1000px) rotate3d(0,1,0,0deg)`,
            transition: `transform 0.7s`,
            transitionTimingFunction: `cubic-bezier(0.2,1,0.3,1)`,
          },
          ":hover": {
            borderBottom: `3px solid var(--highlightColor)`,
          },
          ":hover .spin": {
            transform: `perspective(1000px) rotate3d(0,1,0,180deg)`,
          },
        }}
      >
        He<span className="spin">x</span>fo<span className="spin">x</span>
      </h5>
    </BlockLink>
  );
};

export default HeaderLogo;
