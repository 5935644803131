import React from "react";

import { rhythm, scale } from "../../utils/typography";
import presets from "../../utils/presets";
import { serifFam } from "../../utils/fonts";

const headerStyle = {
  color: `var(--highlightColor)`,
  fontFamily: serifFam,
  marginTop: rhythm(1.5),
  marginBottom: rhythm(0.5),
};

const codeHighlightStyles = {
  "& .gatsby-highlight": {
    marginTop: rhythm(2),
    marginBottom: rhythm(2),
    marginLeft: `-${rhythm(1)}`,
    width: `calc(100% + ${rhythm(2)})`,
    [presets.Tablet]: {
      marginLeft: `-${rhythm(2)}`,
      width: `calc(100% + ${rhythm(4)})`,
    },

    "::-webkit-scrollbar": {
      width: `6px`,
      height: `6px`,
    },
    "::-webkit-scrollbar-thumb": {
      background: `#f4d1c6`,
    },
    "::-webkit-scrollbar-track": {
      background: `#faede5`,
      borderRadius: `0 0 4px 4px`,
    },
    "& pre": {
      padding: `${rhythm(1)} ${rhythm(2)}`,
      boxShadow: `inset 0px 10px 8px -10px #000,
                  inset 0px -10px 8px -10px #000`,
    },
  },
};

const contentStyles = {
  " img": {
    marginLeft: `-${rhythm(1)}`,
    width: `calc(100% + ${rhythm(2)})`,
    maxWidth: `none`,
    [presets.Tablet]: {
      marginLeft: `-${rhythm(2)}`,
      width: `calc(100% + ${rhythm(4)})`,
      maxWidth: `none`,
    },
  },
  " h1": headerStyle,
  " h2": headerStyle,
  " h3": headerStyle,
  " h4": headerStyle,
  " h5": headerStyle,
  " h6": headerStyle,
  " a": { ...scale(-0.15) },
  ...codeHighlightStyles,
};

const Content = ({ html, css, ...props }) => (
  <div
    css={{
      ...contentStyles,
      ...css,
    }}
    dangerouslySetInnerHTML={{ __html: html }}
    {...props}
  />
);

export default Content;
