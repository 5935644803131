import React from "react";

import { rhythm } from "../../utils/typography";
import presets from "../../utils/presets";

const NavContainer = ({ children, css, ...props }) => (
  <div
    css={{
      padding: `0 ${rhythm(0.8)} 0`,
      [presets.Phablet]: {
        padding: `0 ${rhythm(1)} 0`,
        maxWidth: rhythm(24),
        marginLeft: `auto`,
        marginRight: `auto`,
      },
      [presets.Tablet]: {
        maxWidth: `none`,
      },
      [presets.Desktop]: {
        display: `grid`,
        maxWidth: rhythm(40),
      },
      [presets.DesktopLg]: {
        maxWidth: rhythm(42),
      },
      ...css,
    }}
    className="container"
    {...props}
  >
    {children}
  </div>
);

export default NavContainer;
