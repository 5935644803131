import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import BreakpointDisplay from "../breakpoint-display";
import Container from "../container";
import HeaderLogo from "../header-logo";
import Footer from "../footer";
import FooterList from "../footer-list";
import NavContainer from "../nav-container";
import TopNavigation from "../top-navigation";
import Spacer from "../spacer";

import { rhythm, scale } from "../../utils/typography";
import presets from "../../utils/presets";
import colors from "../../utils/colors";

import "../../css/prism-theme.css";

export default ({ children }) => (
  <div>
    <Helmet
      defaultTitle={`Hexfox - Automate your job`}
      titleTemplate={`%s | Hexfox`}
    >
      <meta
        name="google-site-verification"
        content="3JdeRqsbqOnEV_n1NQNn4LXZ8Xhw-iOGquyrsQy8L1k"
      />
      <meta name="apple-mobile-web-app-title" content="Hexfox" />
      <meta name="application-name" content="Hexfox" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="theme-color" content="#00ab7c" />
      <meta name="twitter:site" content="@hexfox" />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Hexfox" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-16x16.png"
        sizes="16x16"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00ab7c" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="stylesheet" href="https://use.typekit.net/juw1vpt.css" />
    </Helmet>
    <NavContainer>
      <TopNavigation />
    </NavContainer>
    {children}
    <Footer>
      <Spacer size={2} />
      <Container>
        <div
          css={{
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-between`,
            [presets.Tablet]: {
              flexDirection: `row`,
            },
          }}
        >
          <FooterList title="Topics">
            <Link to="/t/web-scraping/">Web Scraping</Link>
            <Link to="/t/browser-automation/">Browser Automation</Link>
            {/* <Link to="/t/community/">Community</Link> */}
          </FooterList>
          {/*
              <FooterList title="Products">
                <Link to="TODO">Product 1</Link>
                <Link to="TODO">Product 2</Link>
                <Link to="TODO">Product 3</Link>
                <Link to="TODO">Product 4</Link>
              </FooterList>
              */}
          <FooterList title="Hexfox">
            <Link to="/about/">About</Link>
            {/*<Link to="/our-values/">Our Values</Link>*/}
            <a
              href="https://github.com/hexfox"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
            <a
              href="https://twitter.com/hexfox"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </FooterList>
        </div>
        <Spacer size={3} />
        <HeaderLogo textColor="white" />
        <div
          css={{
            ...scale(-0.2),
            color: colors.grayNickel,
          }}
        >
          <p css={{ marginBottom: rhythm(0) }}>
            &copy; 2016-{new Date().getFullYear()} Hexfox
          </p>
          <p
            css={{
              " a": {
                color: colors.grayNickel,
                marginRight: rhythm(0.5),
                fontWeight: `normal`,
                borderBottom: `1px solid var(--highlightColor)`,
                ":hover": { background: `none`, color: `white` },
              },
            }}
          >
            <Link to="/privacy/">Privacy Policy</Link>
            <Link to="/terms/">Terms</Link>
          </p>
        </div>
      </Container>
      <Spacer size={0.5} />
    </Footer>
    {process.env.NODE_ENV !== "production" && <BreakpointDisplay />}
  </div>
);
