export const breakpoints = {
  mobile: `(min-width: 400px)`,
  Mobile: `@media (min-width: 400px)`,
  phablet: `(min-width: 550px)`,
  Phablet: `@media (min-width: 550px)`,
  tablet: `(min-width: 800px)`,
  Tablet: `@media (min-width: 800px)`,
  desktop: `(min-width: 980px)`,
  Desktop: `@media (min-width: 980px)`,
  desktopLg: `(min-width: 1100px)`,
  DesktopLg: `@media (min-width: 1100px)`,
  wide: `(min-width: 1450px)`,
  Wide: `@media (min-width: 1450px)`,
  vWide: `(min-width: 1650px)`,
  VWide: `@media (min-width: 1650px)`,
};

export const dimensions = {
  contentWidth: 20,
};

export const borderRadius = {
  radius: 3,
  radiusLg: 5,
};

export const zIndexes = {
  zIndexTop: 1000,
  zIndexBottom: 1,
};

export const offsets = {
  logoOffset: 1.7,
};

export default {
  ...breakpoints,
  ...dimensions,
  ...borderRadius,
  ...offsets,
  ...zIndexes,
};
