import React from "react";

import { rhythm } from "../../utils/typography";

const Spacer = ({ size, ...props }) => (
  <div
    className="spacer"
    css={{
      minHeight: rhythm(size),
      maxHeight: rhythm(size),
      flex: `0 0 ${rhythm(size)}`,
    }}
    {...props}
  />
);

export default Spacer;
