import React from "react";
import ReactModal from "react-modal";

import colors from "../../utils/colors";
import presets from "../../utils/presets";

const styles = {
  parent: {
    // react-modal requires these overlay/content keys for inline style usage.
    // see https://reactcommunity.org/react-modal/examples/inline_styles.html

    // TODO: fix animation/transitioning - perhaps use rodal?
    overlay: {
      backgroundColor: `rgba(0, 0, 0, .5)`,
    },
    content: {
      position: `absolute`,
      left: `15px`,
      top: `15px`,
      right: `15px`,
      bottom: `unset`,
      padding: 0,
      display: `flex`,
      zIndex: presets.zIndexTop,
      justifyContent: `flex-end`,
      perspective: `2000px`,
      background: colors.white,
      boxShadow: `0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1)`,
      borderRadius: presets.radiusLg,
      // transformOrigin: `100% 0`,
      // willChange: `transform,opacity`,
      // transitionProperty: `transform,opacity`,
      // transitionDuration: `.25s`,
      // Below change on open
      // transform: open ? `none` : `scale(.95)`,
      // opacity: open ? 1 : 0,
      // pointerEvents: open ? `auto` : `none`,
    },
  },
  inner: {
    background: colors.white,
    overflow: `scroll`,
    position: `relative`,
    whiteSpace: `nowrap`,
    flexGrow: 1,
    flexBasis: 0,
  },
  closeButton: {
    border: `none`,
    display: `block`,
    position: `absolute`,
    right: 4,
    top: 0,
    width: `51px`,
    height: `51px`,
    fontSize: 0,
    cursor: `pointer`,
    color: colors.pomegranate,
    borderBottom: `none`,
    ":before,:after": {
      content: "''",
      position: `absolute`,
      background: colors.pomegranate,
      borderRadius: `1px`,
      left: `14px`,
      right: `14px`,
      top: `30px`,
      height: `3px`,
      transform: `rotate(45deg)`,
      transition: `transform .3s`,
    },
    ":after": {
      transform: `rotate(-45deg)`,
    },
    ":hover": {
      background: `transparent`,
      ":before": {
        transform: `rotate(-45deg)`,
      },
      ":after": {
        transform: `rotate(45deg)`,
      },
    },
  },
};

typeof document !== "undefined" && ReactModal.setAppElement("#___gatsby");

const Modal = ({ open, onCloseHandler, contentLabel, children, ...props }) => {
  return (
    <ReactModal
      isOpen={open}
      contentLabel={contentLabel}
      onRequestClose={onCloseHandler}
      style={styles.parent}
      {...props}
    >
      <div css={styles.inner}>
        <button onClick={onCloseHandler} css={styles.closeButton}>
          Close
        </button>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
