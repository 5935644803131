import React from "react";

import { Link } from "gatsby";

const BlockLink = ({ css, ...props }) => (
  <Link
    css={{
      borderBottom: `none`,
      boxShadow: `none`,
      "&:hover": {
        borderBottom: `none`,
        boxShadow: `none`,
      },
      ...css,
    }}
    {...props}
  />
);

export default BlockLink;
