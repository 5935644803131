import React from "react";

import { rhythm, scale } from "../../utils/typography";
import colors from "../../utils/colors";
import presets from "../../utils/presets";

const itemStyle = {
  " a": {
    color: colors.grayNickel,
    fontWeight: `normal`,
    linkDecoration: `none`,
    border: `none`,
    ":hover": {
      color: colors.white,
    },
  },
};

export default ({ title, children, ...props }) => {
  return (
    <div
      css={{ width: `100%`, [presets.Tablet]: { width: `33.3%` } }}
      {...props}
    >
      <h4
        css={{
          ...scale(1 / 2),
          color: `white`,
          display: `inline-block`,
          borderBottom: `4px solid var(--highlightColor)`,
          marginBottom: rhythm(1),
        }}
      >
        {title}
      </h4>
      <ul css={{ listStyle: `none`, margin: 0 }}>
        {children.map((link, i) => [
          <li css={itemStyle} key={i}>
            {link}
          </li>,
        ])}
      </ul>
    </div>
  );
};
