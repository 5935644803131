import get from "lodash/get";

import { simpleNonSecureHash } from "./hash";

const bodyStyles = {
  default: {
    backgroundImage: `linear-gradient(80deg, #9d50bb, #6e48aa)`,
    "--highlightColor": `#f55220`,
  },
  index: {
    backgroundImage: `linear-gradient(80deg, #5f5376, #293c49)`,
    "--highlightColor": `#f55220`,
  },
  "web-scraping": {
    backgroundImage: `linear-gradient(80deg, #11968b, #129464)`,
    "--highlightColor": `#f27676`,
  },
  "browser-automation": {
    backgroundImage: `linear-gradient(80deg, #11968b, #129464)`,
    "--highlightColor": `#f27676`,
  },
  "getting-started-with-web-scraping": {
    backgroundImage: `linear-gradient(80deg, #6a3093, #a044ff)`,
    "--highlightColor": `#f55220`,
  },
  1: {
    backgroundImage: `linear-gradient(to left, #44a08d, #44a59b)`,
    "--highlightColor": `#f57820`,
  },
  2: {
    backgroundImage: `linear-gradient(80deg, #02aab0, #00cdac)`,
    "--highlightColor": `#0f499f`,
  },
  3: {
    backgroundImage: `linear-gradient(80deg, #11968b, #129464)`,
    "--highlightColor": `#f27676`,
  },
  4: {
    backgroundImage: `linear-gradient(80deg, #536976, #292e49)`,
    "--highlightColor": `#f55220`,
  },
  5: {
    backgroundImage: `linear-gradient(80deg, #5f5376, #293c49)`,
    "--highlightColor": `#f55220`,
  },
  6: {
    backgroundImage: `linear-gradient(80deg, #ff7733, #ff5c5f)`,
    "--highlightColor": `#33bbff`,
  },
  7: {
    backgroundImage: `linear-gradient(80deg, #745376, #292a49)`,
    "--highlightColor": `#f55220`,
  },
  8: {
    backgroundImage: `linear-gradient(80deg, #1488cc, #3859a4)`,
    "--highlightColor": `#f27676`,
  },
  9: {
    backgroundImage: `linear-gradient(80deg, #cb356b, #bd3f32)`,
    "--highlightColor": `#35cb95`,
  },
  0: {
    backgroundImage: `linear-gradient(80deg, #16222a, #244e64)`,
    "--highlightColor": `#ff7a2d`,
  },
};

export function getBodyStyle(slug) {
  return get(bodyStyles, slug, bodyStyles.default);
}

export function getBodyStyleByHash(slug) {
  const hash = simpleNonSecureHash(slug);
  const last = hash.toString().slice(-1);
  return bodyStyles[last];
}
