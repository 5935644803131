import React from "react";

import BlockLink from "../block-link";
import HeaderLogo from "../header-logo";
import MobileNavigation from "../mobile-navigation";
import { rhythm, scale } from "../../utils/typography";
import presets from "../../utils/presets";
import { sansFam, serifFam } from "../../utils/fonts";

const navItemStyles = {
  ...scale(-1 / 3),
  display: `inline-block`,
  color: `inherit`,
  fontWeight: 700,
  textDecoration: `none`,
  textTransform: `uppercase`,
  letterSpacing: `0.03em`,
  marginRight: `1rem`,
  paddingBottom: `4px`,
  lineHeight: 1,
  position: `relative`,
  top: 0,
  transition: `all .15s ease-out`,
  borderBottom: `3px solid transparent`,
  "& .spin": {
    display: `inline-block`,
    position: `relative`,
    transform: `perspective(1000px) rotate3d(0,1,0,0deg)`,
    transition: `transform 2.4s`,
    transitionTimingFunction: `cubic-bezier(0.2,1,0.3,1)`,
  },
  ":hover": {
    color: `white`,
    background: `transparent`,
    borderBottom: `3px solid var(--highlightColor)`,
  },
  ":hover .spin": {
    transform: `perspective(1000px) rotate3d(0,1,0,360deg)`,
  },
};

const NavItem = ({ linkTo, title }) => {
  const title_first_char = title.substr(0, 1);
  const title_rest = title.substr(1);
  return (
    <li
      css={{
        display: `inline-block`,
        margin: 0,
      }}
    >
      <BlockLink to={linkTo} css={navItemStyles}>
        <span className="spin">{title_first_char}</span>
        {title_rest}
      </BlockLink>
    </li>
  );
};

export default ({ css }) => {
  return (
    <header
      css={{
        fontFamily: sansFam,
        display: `flex`,
        alignItems: `center`,
        color: `white`,
        marginTop: rhythm(0.8),
        marginBottom: rhythm(0.8),
        [presets.Tablet]: {
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        },
        [presets.Desktop]: {
          marginTop: rhythm(1.5),
          marginBottom: rhythm(1.5),
        },
        ...css,
      }}
    >
      <HeaderLogo textColor="white" />
      <nav css={{ flex: 1, marginLeft: rhythm(1) }}>
        <ul
          role="navigation"
          css={{
            display: `none`,
            [presets.Tablet]: {
              display: `flex`,
              justifyContent: `flex-end`,
              margin: 0,
              padding: 0,
              listStyle: `none`,
            },
          }}
        >
          <div
            css={{
              marginRight: `auto`,
              display: `none`,
              [presets.Desktop]: { display: `inline-block` },
            }}
          >
            <i css={{ fontFamily: serifFam }}>Automating your lost hours!</i>
          </div>
          <div>
            <NavItem linkTo="/t/web-scraping/" title="Web Scraping" />
            <NavItem
              linkTo="/t/browser-automation/"
              title="Browser Automation"
            />
          </div>
        </ul>
        <MobileNavigation />
      </nav>
    </header>
  );
};
