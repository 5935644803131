import React from "react";

import colors from "../../utils/colors";
import { sansFam } from "../../utils/fonts";

export default ({ children }) => {
  return (
    <footer
      className="footer"
      css={{
        background: colors.shark,
        color: `white`,
        fontFamily: sansFam,
      }}
    >
      {children}
    </footer>
  );
};
