import React from "react";
import { Link } from "gatsby";

import logo from "../hexfox-logo.svg";
import Modal from "../modal";
import colors from "../../utils/colors";
import presets from "../../utils/presets";
import { rhythm, scale } from "../../utils/typography";

const hamburgerLine = {
  position: `absolute`,
  width: `24px`,
  height: `3px`,
  borderRadius: `1px`,
  background: `inherit`,
};

const styles = {
  link: {
    position: `relative`,
    borderBottom: `none`,
    borderRadius: presets.radiusLg,
    display: `inline-block`,
    width: `50px`,
    height: `50px`,
    textTransform: `none`,
    cursor: `pointer`,
    textAlign: `center`,
    transition: `color .1s ease`,
    userSelect: `none`,
    ":hover": {
      color: `transparent`,
      background: `transparent`,
      "> *": {
        background: `var(--highlightColor)`,
        color: `var(--highlightColor)`,
      },
    },
  },
  hamburger: {
    ...hamburgerLine,
    fontSize: 0,
    display: `block`,
    left: `13px`,
    top: `23px`,
    background: colors.white,
    ":before": { ...hamburgerLine, content: `''`, left: 0, top: `-9px` },
    ":after": { ...hamburgerLine, content: `''`, left: 0, top: `9px` },
  },
  linkListStyles: {
    listStyle: `none`,
    padding: 0,
    margin: 0,
  },
};

const Hamburger = () => <h2 css={styles.hamburger}>Menu</h2>;

const PrimaryLink = ({ children, ...props }) => (
  <Link css={{ ...scale(0.2), borderBottom: `none` }} {...props}>
    {children}
  </Link>
);

const SecondaryLink = ({ children, ...props }) => (
  <Link css={{ fontWeight: `100`, borderBottom: `none` }} {...props}>
    {children}
  </Link>
);

class MobileNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    return (
      <div
        css={{
          display: `flex`,
          justifyContent: `flex-end`,
          [presets.Tablet]: {
            display: `none`,
          },
        }}
        title="Menu"
      >
        <a css={styles.link} onClick={this.toggle}>
          <Hamburger />
        </a>
        <Modal
          open={this.state.open}
          contentLabel="Mobile Navigation"
          onCloseHandler={e => this.toggle(e)}
        >
          <div
            className="primary-nav"
            css={{
              marginTop: rhythm(1),
              padding: `15px 30px`,
            }}
          >
            <h4
              css={{
                fontWeight: 100,
                marginBottom: rhythm(1),
                letterSpacing: `.025em`,
                color: colors.baliHai,
                textTransform: `uppercase`,
              }}
            >
              Topics
            </h4>
            <div css={{ display: `grid`, gridTemplateColumns: `1fr 1fr` }}>
              <ul css={{ ...styles.linkListStyles, flexGrow: 1 }}>
                <li>
                  <PrimaryLink to="/t/web-scraping/" onClick={this.toggle}>
                    Web Scraping
                  </PrimaryLink>
                </li>
                <li>
                  <PrimaryLink
                    to="/t/browser-automation/"
                    onClick={this.toggle}
                  >
                    Browser Automation
                  </PrimaryLink>
                </li>
              </ul>
              <ul css={{ ...styles.linkListStyles, flexGrow: 1 }}>
                {/* 2nd Nav currently empty*/}
              </ul>
            </div>
          </div>
          <div
            className="secondary-nav"
            css={{
              borderTop: `2px solid #f6f9fc`,
              padding: `15px 30px`,
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
            }}
          >
            <ul css={{ ...styles.linkListStyles, flexGrow: 1 }}>
              <li>
                <SecondaryLink to="/about/" onClick={this.toggle}>
                  About Hexfox
                </SecondaryLink>
              </li>
              <li>
                <SecondaryLink to="/our-values/" onClick={this.toggle}>
                  Our Values
                </SecondaryLink>
              </li>
              <li>
                <SecondaryLink to="/contact/" onClick={this.toggle}>
                  Contact
                </SecondaryLink>
              </li>
            </ul>
            <ul css={{ ...styles.linkListStyles, flexGrow: 3 }}>
              {/* 2nd Nav currently empty*/}
            </ul>
          </div>
          <div
            css={{
              background: `#f6f9fc`,
              display: `block`,
              fontWeight: 600,
              padding: `0 30px`,
              borderBottom: `none`,
            }}
          >
            <Link
              css={{
                borderBottom: `none`,
                transition: `background-color .4s`,
                " span": {
                  display: `none`,
                },
                ":hover": {
                  " span": { display: `inline-block` },
                },
              }}
              onClick={this.toggle}
              to="/"
            >
              <img
                src={logo}
                css={{
                  display: `inline-block`,
                  height: rhythm(0.8),
                  width: rhythm(0.8),
                  marginTop: `30px`,
                  marginRight: rhythm(0.2),
                  verticalAlign: `middle`,
                }}
                alt="Hexfox logo"
              />
              Hexfox <span>⤶</span>
            </Link>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MobileNavigation;
